.OrderMessageLabel {
  display: block;
}

.savedorderscontrol:empty {
  display: none;
}

.ExceptionMessageLabel {
  font-weight: bold;
}
