.call-offs {
    text-align: right;

    table {
        margin-top: 10px;
        text-align: left;
        width: 100% !important;

        input[type="text"] {
            text-align: center;
        }
    }

    .cssgrid {
        th {
            border-bottom: 1px solid #e7e7e9;
        }
    }

    .cssOpadQty {
        input {
            text-align: center;
        }
    }

    .productsIconPanel {
        display: block;

        img {
            height: 36px;
            width: 36px;
        }
    }


    .productsImageHyperLink {
        img {
            max-height: 85px;
            margin: auto;
            width: unset !important;
        }
    }

    tr {

        td {
            padding: 0 10px;
        }

        td.cssOpadCode {
            min-width: 130px;
            max-width: 130px;
            word-break: break-all;
            white-space: unset !important;
        }

        td:not(:first-of-type) {
            border-bottom: 1px solid #e7e7e9;
        }

        th:nth-of-type(6) {
            min-width: 75px;
        }

        th:last-of-type {
            min-width: 120px;
        }
    }

    .csscallOffImageButton {
        margin-top: 10px;
    }

    .vathidden,
    .incVat {
        display: none;
    }
}