.fullWidthImage {
  padding-bottom: calc(100vh - #{$headerSize} - 40px);
  background: transparent;
  position: relative;

  &.tall {
    padding-bottom: 45%;
  }


  .text {
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);

    &.anchorBottom {
      top: auto;
      bottom: 80px;
      transform: translateY(0%);
    }
  }

  .image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &.contain {
      background-size: contain;
    }
  }

  &.contained {
    padding-bottom: 50%;
  }
}


.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;

  img {
    width: 100px;
  }
}

.video {
  @extend .image;

  video:not(.notHeader) {
    height: calc(100vh - (#{$headerSize} + 40px));
  }

  video {

    width: 100%;
    object-fit: cover;
  }
}

.fullWidthVideo {
  width: 100%;
  padding: 0;
  cursor: pointer;

  img {
    display: none;
    //cursor: pointer;
  }

  //img + video {visibility: hidden;}


  img,
  video {
    cursor: pointer;
    width: 100%;
  }
}
