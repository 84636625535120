.timeline {
  .timeline-entry {
    position: relative;
    text-align: left;
    padding: 0 20px 55px;

    &:before {
      position: absolute;
      top: 0;
      bottom: -4px;
      left: 0;
      border-left: 1px solid $lightGrey;
      content: "";
      display: inline-block
    }

    &:after {
      position: absolute;
      top: 0;
      left: -9px;
      display: inline-block;
      width: 19px;
      height: 19px;
      background: $heatonsOrange;
      border-radius: 50%;
      content: ''
    }
  }

  .timeline-title {
    transition: all .33s ease-in-out;

    &:hover {
      color: $heatonsOrange;
      text-decoration: none;
    }
  }

  .time {
    display: block;
    font-style: italic;
    font-size: 12px;
    line-height: 26px;
    color: $heatonsOrange;
  }

  a {
    color: $fontColor;
    text-decoration: none;
  }
}

@media (min-width: 768px) {
  .timeline {
    .timeline-entry {
      max-width: 50%;
    }

    [class*=col-]:nth-of-type(even) {
      .timeline-entry {
        margin-right: auto;
        text-align: right;

        &:before {
          left: auto;
          right: -1px;
        }

        &:after {
          left: auto;
          right: -10px;
        }
      }
    }

    [class*=col-]:nth-of-type(odd) {
      .timeline-entry {
        margin-left: auto;
      }
    }
  }
}