.order-pads {
  .SectorBulletedList {
    list-style: none;
    max-width: 400px;
    padding-left: 0;
    margin-bottom: 30px;

    li {
      padding: 20px 20px 20px 20px;
      background-color: #e7e7e9;
      text-transform: uppercase;
      color: #e7792b !important;
      margin-bottom: 5px;
    }

    a {
      color: $heatonsOrange !important;
      @extend .h4;
    }
  }
}

.orderpadpanel {
  display: none;
}

.orderpadpanel.processed {
  display: block;
}

.backHyperLink {
  background-color: #6e6e70;
  color: #fff;
  padding: 10px 10px;
  display: inline-block;
  height: 40px;
  text-transform: uppercase;
  width: 175px;
  text-align: center;

  &:hover {
    color: white;
    text-decoration: underline;
  }
}

.cssorderImageButton {
  background-color: $heatonsOrange;
  color: #fff;
  padding: 10px 10px;
  display: inline-block;
  height: 40px;

  &:hover {
    color: white;
    text-decoration: underline;
  }
}

.order-pads {
  table:first-of-type {
    margin-bottom: 10px;
  }
}

.productdetailsdatalist {
  @extend .mt-3;
}

#orderpadproducts {
  .productsIconPanel {
    display: none;
  }


  table {
    width: 100% !important;

    table {
      display: inline-block;
      margin-top: 15px;
      font-size: 1.2em;
      width: unset !important;

      td {
        border-bottom: 1px solid #e7e7e9 !important;
      }
    }
  }

  .gridsubheading {
    td:not(.cssOpadDesc) {
      display: none;
    }

    .cssOpadDesc {
      color: #6e6e70;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.1em;
    }
  }

  .cssgrid {
    th {
      border-bottom: 1px solid #e7e7e9;
    }
  }

  tr + .gridsubheading {
    border-top: 1px solid #e7e7e9;
  }

  .cssOpadQty {
    input {
      text-align: center;
    }
  }


  .productsImageHyperLink {
    img {
      max-height: 85px;
      margin: auto;
      width: unset !important;
    }
  }

  tr:not(.gridsubheading) {

    td {
      padding: 0 10px;
    }

    td.cssOpadCode {
      min-width: 130px;
      max-width: 130px;
      word-break: break-all;
      white-space: unset !important;
    }

    td:not(:first-of-type) {
      border-bottom: 1px solid #e7e7e9;
    }
  }
}

.orderpadslinkpanel {
  padding: 5px;
}

.opadRangeWrapper {
  @extend .row;

  .contractRangeWrapper {
    @extend .col-12, .col-lg-6, .col-xl-2, .mb-2;
  }

  .contractRangeThumb {
    text-align: center;
  }

  .contractRangeHeading a {
    color: $heatonsOrange
  }
}