.hoverCard {
    padding-bottom: 50%;
    background: #EDEDED;
    position: relative;
    display: block;
    height: 171px;
    color: #575756;
    margin-bottom: 40px;
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);

    .inner {
        transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        padding: 20px;
        color: #575756;

        span {
            position: absolute;
            bottom: 20px;
            left: 20px;
            display: block;
            height: 31px;
            line-height: 31px;

            img {
                position: relative;
                top: -2px;
                margin-right: 5px;
            }
        }
    }

    .rollover {
        transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        padding: 20px;
        color: #FFF;
        opacity: 0;
        background: #575756;
        padding-right: 40%;
    }

    .image {
        position: absolute;
        bottom: 0px;
        right: 20px;
        width: 40%;
        height: 120%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom center;
        z-index: 9;
    }

    .hover {
        display: none;
    }

    &:hover {

        .inner {
            color: #fff;
            background: #575756;
        }

        .noHover {
            display: none;
        }

        .hover {
            display: unset;
        }

    }

    &.greenHover {
        &:hover {

        }
    }
}