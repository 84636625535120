.scrollerTable{
	padding:30px;
	h5{
		border-bottom:1px solid #222;
		padding-bottom:10px;
		margin-bottom:30px;
	}	

	p{
		font-size: 16px;
	}

	a, span.a{
		font-size:13px;
		font-weight: 300;
		color:#222;
		border-bottom:1px solid rgba(0,0,0,0.2);
		margin-bottom: 10px;
		display: block;
		padding-bottom:5px;
		&:hover{
			text-decoration: none;
			font-weight: 400;
		}
	}

	span.a{
		&:hover{
			text-decoration: none;
			font-weight: 300;
		}	
		strong{
			font-weight: 600;
		}
	}
}