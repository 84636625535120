#FastOrderTable {
  input[type="text"] {
    @extend .form-control;
    border-radius: 0;
  }

  input.FastOrderColumnHeader {
    display: none !important;
  }

  tr:last-of-type {
    td:first-of-type {
      display: none;
    }
  }

  input[type=submit] {
    display: block;
    width: 100%;
  }
}