.favourite-products {

    table {
        text-align: left;
        width: 100% !important;
    }

    .gridsubheading {
        td {
            color: #6e6e70;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 1.1em;
        }
    }

    .cssgrid {
        th {
            border-bottom: 1px solid #e7e7e9;
        }
    }

    tr + .gridsubheading {
        border-top: 1px solid #e7e7e9;
    }

    .cssOpadQty {
        input {
            text-align: center;
        }
    }

    .productsIconPanel {
        display: block;

        img {
            height: 36px;
            width: 36px;
        }
    }


    .productsImageHyperLink {
        img {
            max-height: 85px;
            margin: auto;
            width: unset !important;
        }
    }

    tr:not(.gridsubheading) {

        td {
            padding: 0 10px;

            &:first-of-type {

                padding: 5px 10px;
            }
        }

        td.cssOpadCode {
            min-width: 130px;
            max-width: 130px;
            word-break: break-all;
            white-space: unset !important;
        }

        td:not(:first-of-type) {
            border-bottom: 1px solid #e7e7e9;
        }

        td:last-of-type {
            a {
                @extend .btn, .btn-danger;
                color: white;
                border-radius: 0;
            }
        }
    }

    .productsBuyImageButton {
        width: 140px;
        text-align: center;
        margin-top: 20px;
    }

    .productspager {
        select {
            @extend .form-control;
            display: inline-block;
            max-width: 115px;
            border-radius: 0;
        }
    }

    input[type='text'] {
        @extend .form-control;
        border-radius: 0;
        text-align: center;
    }

}

.favourite-products {
    display: none;
}

.favourite-products.processed {
    display: block;
}