.cart-saved-sidebar {

  .sticky-top {
    z-index: 1 !important;
  }

  .sidebar-cart {
    padding: 17px 25px;
    margin-bottom: 10px;
    background: $lightGrey;
    color: $fontColor;

    .minicartcontrol {
      display: block !important;

      table {
        width: 100%;
      }

      .productsImageHyperLink {
        display: none;
      }

      .hlMCProdDesc {
        display: block;
      }

      .lblMCProdCode {
        display: inline-block;
        min-width: 120px;
      }

      .lblMCProdQty {
        font-weight: bold;
        color: $fontColor;

        &:before {
          display: inline-block;
          content: "Quantity";
          margin-right: 3px;
        }
      }

      .prodPrices {
        display: inline-block;
        color: $fontColor;
        margin-left: 10px;
      }

      td {
        padding-bottom: 15px;
      }

      table + div {
        text-align: right;
        margin-bottom: 10px;

        span {
          text-decoration: none !important;
          text-transform: uppercase;
        }

      }

      .cssbutton {
        display: block;
        margin: auto;
        padding: 5px 10px;
      }
    }
  }

  a.hlMCProdDesc {
    color: $fontColor;
    max-height: 20px;
    display: block;
    overflow-y: hidden;
  }

  .csscontrolBoxHeader {
    margin-bottom: 5px;

    span[id] {
      text-transform: uppercase;
      display: block;
      font-size: 1.5em;
      padding-bottom: 5px;
      border-bottom: 1px solid #58585a;
      margin-bottom: 10px;
    }
  }
}

.savedorderscontrol {
  padding: 17px 25px;
  margin-bottom: 30px;
  background: $lightGrey;
  color: $fontColor;

  table {
    td:first-of-type {
      width: 100% !important;
    }

    td:last-of-type {
      text-align: right;
    }
  }
}