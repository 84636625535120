.twoColImage {
  background: #ebebeb;
  padding-bottom: 420px;
  overflow: hidden;
  position: relative;

  &:hover .image {
    transform: scale(1.1);
  }

  &:hover .caption {
    background: $heatonsOrange;
  }

  .image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  .caption {
    position: absolute;
    bottom: 0%;
    left: 0px;
    width: 100%;
    padding: 15px;
    background: #878787;
    color: #fff;
    font-size: 22px;

    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);

    &.transparent {
      background: rgba(0, 0, 0, 0.4);
    }

  }

}

.ctaBox {
  background: #76B82A;
  color: #fff;
  font-size: 38px;
  padding: 20px;
  display: block;
  font-weight: 300;
  line-height: 42px;

  strong {
    font-weight: 800;
  }

  &:hover {
    color: #fff;
    text-decoration: none;
  }

  span {
    font-size: 16px;
    display: block;
    margin-top: 20px;
    border-top: 1px solid #fff;
    padding-top: 10px;
  }
}

