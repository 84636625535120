.squareCard {
    padding-bottom: 100%;
    background: #fafafa;
    position: relative;
    overflow: hidden;

    .image {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }

    &:hover {
        .caption {
            transform: translateY(-100%);
        }
    }

    .captionHeader {
        height: 65px;
    }

    .caption {
        position: absolute;
        top: 100%;
        left: 0px;
        width: 100%;
        padding: 15px;
        background: #878787;
        color: #fff;
        font-size: 22px;
        height: 100%;
        transform: translateY(-95px);


        transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);

        &:hover {
            background-color: rgba(231, 121, 43, 0.7);
        }

        .text {
            border-top: 1px solid #fff;
            font-size: 18px;
            margin-top: 20px;
            padding-top: 20px;
        }
    }
}