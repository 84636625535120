#menuShadow {
  background-color: #e7e7e9;
}

#MainMenu {
  background-color: rgb(160, 160, 160);

  .menu-ul {
    padding-left: 15px;
    padding-right: 15px;
    width: 95%;
    margin: auto;
  }

  a, button {
    outline: none;
  }

  .menu-ul + .submenu {
    top: 40px;
  }

  .mobile li {
    display: block;
  }

  .submenu {
    position: absolute;
    left: 0;
    width: 100%;
  }

  .submenu-btn {
    color: #4D4D4D;
    text-transform: uppercase;
    border: none;
    background: none;
    height: 40px;
    padding: 0;
    margin-right: 20px;

    &:hover {
      text-decoration: underline;
    }
  }

  .reveal .submenu {
    display: block;
    animation: fade-in 0.4s;
  }

  .hide .submenu {
    display: none;
  }

  .submenu {
    z-index: 950;
    width: 100%;
    background-color: rgb(185, 185, 185);

    > .submenu {
      background-color: #ededed;
      padding-top: 30px;
      padding-bottom: 30px;
      min-height: 350px;
    }

    .dropDownTitle {
      display: inline-block;
    }
  }
}

.dropDown {
  position: absolute;
  left: 0px;
  top: 40px;
  width: 100%;
  background: #F2F1F1;
  padding: 30px 0px;
  color: #4D4D4D;
  display: none;
}

li:hover .dropDown {
  display: block;
}

.dropDownTitle {
  font-size: 18px;
}

.dropDownMenu {
  li {
    display: block;
    float: none !important;
    line-height: 30px !important;

    a {
      text-transform: uppercase !important;
    }
  }
}

.dropDownTitle {
  color: #e7792b !important;
  text-transform: uppercase;
}

.dropdownLink {
  + p {
    line-height: 1.5;
  }
}

.smaller {

  .dropDownMenu {

    li {
      display: block;
      float: none !important;
      font-size: 14px;
      line-height: 24px !important;

      a {
        text-transform: none !important;
      }
    }
  }

  .dropDownTitle {
    color: #e7792b;
    text-transform: uppercase;
    font-size: 16px;
  }
}

.dropDownAdvert {
  width: 64%;
  height: 160px;
  position: absolute;
  bottom: 0px;
  right: 0px;

  img {
    width: 100%;
  }
}

.viewmore {
  font-weight: 700;
}
