.breadcrumb {
  .homeLinkBack {
    display: none;

    & + .breadcrumbSeparator {
      display: none;
    }
  }
}

.RemoveFilterValue:before {
  display: inline-block;
  content: 'x';
  margin-right: 2px;
  color: red;
}

.DelInfoHeader {
  @extend h5;
  margin-bottom: .5rem;
  font-weight: 500;
  text-transform: uppercase;
}

.prodDetailsImageCont {
  .NewproductImage {
    max-width: 100%;
    max-height: 350px;
    margin: auto;
  }

  .thumbnail:not(:first-of-type) {
    display: inline-block;
    height: 51px !important;
    width: 51px !important;
    margin-top: 10px;
    margin-right: 5px;
    border: 2px solid $lightGrey;

    img {
      height: 48px !important;
      width: 48px !important;
    }
  }
}

.product-details {
  .productDescription {
    padding-right: 15px;
  }

  .prodDetailsImageCont {
    text-align: center;

    a:not(.lbImageAlt):not(.pdfLinkButton):not(.videoLinkButton) {
      display: block;
      margin: auto;
      min-height: 350px;
    }
  }

  .productDetails {
    .NewproductdetailsdescriptionLabel {
      font-size: 26px;
      margin-bottom: 5px;
      text-transform: uppercase;
    }

    .divProductRating {
      display: none;
    }

    .PackSizeLabel {
      margin-top: 10px;

      .prodCodeLabel:last-of-type {
        margin-left: 2px;
        font-weight: bold;
      }
    }
  }

  .ProductDetailsDescription {
    margin-top: 10px;

    .compAttributesWrapper {

      dt {
        @extend h5;
        text-transform: uppercase;
        line-height: 0;
        text-indent: -9999px;

        &:after {
          display: block;
          content: 'DESCRIPTION';
          line-height: initial;
          text-indent: initial;
        }
      }

      dd {
        @extend p;
      }
    }

    .NewproductdetailsLongDescLabel {
      margin-top: 15px;

      &:before {
        display: block;
        content: 'DESCRIPTION';
        line-height: initial;
        text-indent: initial;
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 10px;
      }
    }
  }

  .web-price {
    .WebPriceHeader {
      .YourPriceHeaderLabel:not(.YourPriceHeaderPrice) {
        display: block;
        color: red;
        line-height: 0;
        text-indent: -9999px;

        &:after {
          display: block;
          content: 'Your Online Price:';
          line-height: initial;
          text-indent: initial;
          font-weight: bold;
          font-size: 1.2rem;
        }
      }

      .exVat {
        color: red;
        font-size: 1.2rem;
      }
    }

    .stockLinkPanel {
      .stockLabel {
        text-transform: uppercase;
        color: #76b82a;
        font-weight: bold;

        .stockError {
          color: red;
        }
      }
    }

    .sellPriceLabel {
      text-decoration: line-through;
      margin-right: 5px;
    }

    .SaveLabel,
    .sellPriceLabelRRP {
      font-weight: bold;
    }
  }

  [id*='quotePanel'] {
    //display: none;
  }

  .product-add {

    .newLineCheckBox {
      input {
        display: none;

        &:checked + label {
          color: $heatonsOrange;
          margin-right: 1px;

          &:after {
            position: relative;
            content: '✔';
          }
        }
      }

      label {
        @extend .form-check-label;
        margin-top: 5px;
        margin-bottom: 5px;
        cursor: pointer;

        &:hover {
          color: $heatonsOrange;
          text-decoration: underline;
        }
      }
    }

    .buyDiv {
      padding: 5px 0;
      display: block;
      min-height: 40px;

      .pControlQtyTextBox {
        @extend .form-control;
        max-width: calc(100% - 130px);
        display: inline-block;
        border-radius: 0;
        height: 40px;
      }

      .prodDetailsBuyButton {
        display: inline-block;
        width: 125px;
        height: 40px;
      }
    }

    .prodDetailAuthDropDown {
      @extend .form-control;
      width: 100%;
    }

    .FavImgButton {
      //display: none;
    }

    .qtyValid {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }
  }

  .continue-shopping {
    @extend .container-fluid;
    max-width: 100% !important;

    .productdetailbuttons {
      @extend .row;

      .cssbutton {
        @extend .col-12, .col-lg;
        //flex: 0 0 49%;
        //max-width: 49%;
        text-align: center;

        &:last-of-type {
          //margin-left: 1%;
          background-color: #6c757d;
        }
      }
    }
  }


}

.qty-breaks {
  .SaveMoreLabel {
    display: block;
    line-height: 0;
    text-indent: -9999px;

    &:after {
      display: block;
      content: "SAVE MORE IF YOU ORDER MORE";
      line-height: normal;
      text-indent: 0;
      font-size: 1.25rem;
      margin-top: 10px;
    }
  }

  .QtyBreaksList {
    margin-top: 10px;
    padding: 0;

    .QtyBreakListItem {
      display: inline-block;
      font-size: 0;
      margin: 0;
      padding: 5px;
      border: 2px solid $lightGrey;

      &:nth-of-type(even) {
        border-right: 0;
        border-left: 0;
      }

      &:last-of-type {
        border-right: 2px solid $lightGrey;
      }

      span {
        display: block;
        font-size: initial;
        padding: 5px;
        text-align: center;

        &:last-of-type {
          border-top: 2px solid $lightGrey;
        }
      }
    }
  }
}

.lowerDetails:not(.processed) {
  .col-12 {
    display: none;
  }
}

.lowerDetails {
  hr {
    display: none;
    color: #575756;
    height: 3px;
  }

  .spec_features + hr,
  .relatedProducts + hr {
    display: block;
  }

  .spec, .features {
    .ProductDetailsFeatures:empty + .revealAllTableBtn,
    .ProductDetailsSpecification:empty + .revealAllTableBtn {
      display: none !important;
    }

    table {
      @extend .table, .table-sm;

      tr {
        height: 30px;

        td:first-of-type {
          width: 300px;
          border: 0;
          border-right: 1px solid #b9b9b9;
          padding-left: 10px;

          + td {
            padding-left: 10px;
          }
        }

        td:last-of-type {
          border: 0 !important;
        }

        &:nth-child(odd) {
          background-color: #f6f6f6;
        }

        &:nth-child(even) {
          background-color: #ededed;
        }
      }
    }

    table:not(.revealAll) {
      tr {
        display: none;
      }

      @for $i from 1 through 8 {
        tr:nth-of-type(#{$i}) {
          display: table-row;
        }
      }
    }
  }

  .revealAllTableBtn {
    margin: auto;
    display: block;
  }
}

.relatedProducts {
  margin-top: 20px;

  .ProductDetailsRelated {
    @extend .row;

    @for $i from 1 through 6 {
      .RelatedProductPanel:nth-of-type(#{$i}) {
        display: block;
      }
    }

  }
}

.RelatedProductPanel {
  @extend .col-12, .col-md-6, .col-xl-2;
  display: none;

  &:hover {
    .RelatedPriceLabel,
    .QBQtySpan,
    .RelatedDescLabel,
    .RelatedCodeLabel {
      font-weight: bold;
    }
  }

  .productsImageHyperLink {
    display: block;
    min-height: 120px;

    img {
      margin: auto;
      display: block;
    }
  }

  .RelatedCodeLabel {
    display: block;
    height: 20px;
  }

  .RelatedDescLabel {
    display: block;
    min-height: 60px;
  }

  .RelatedPriceLabel {
    display: block;
    color: $heatonsOrange;
    margin-bottom: 5px;

    span {
      color: #636363;
      font-weight: normal;
    }
  }

  .cssbutton {
    display: none;
  }
}

.additionalProducts {
  margin-top: 20px;

  .ProductDetailsAdditionals {
    @extend .row;

    .AdditionalItemPanel:not(:last-of-type) {
      &:hover {
        .AdditionalPriceLabel,
        .QBQtySpan,
        .AdditionalDescLabel,
        .AdditionalCodeLabel {
          font-weight: bold !important;
        }
      }
    }

    .AdditionalItemPanel {
      &:last-of-type {
        img {
          display: none;
        }

        span {
          display: block;
        }

        .cssbutton {
          display: block;
          text-align: center;
        }
      }
    }

    .AdditionalItemPanel:not(:last-of-type) {
      @extend .col-12, .col-md-6, .col-xl-2;
    }

    .AdditionalItemPanel:last-of-type {
      display: block;
      width: 100%;
      margin-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
    }

    .AdditionalItemPanel {
      display: none;

      .AdditionalPriceLabel {
        display: block;
        color: $heatonsOrange;
        margin-bottom: 5px;

        span {
          color: #636363;
          font-weight: normal;
        }
      }

      .productsImageHyperLink {
        display: block;

        img {
          margin: auto;
          display: block;
        }
      }

      .AdditionalDescLabel {
        display: block;
        overflow: hidden;
        margin-bottom: 5px;
        min-height: 60px;
      }


      .AdditionalButton.cssbutton {
        display: none;
        width: 100%;
        text-align: center;
        margin: auto;
      }
    }

    @for $l from 0 through 5 {
      div[id*='AdditionalItem#{$l}'] {
        display: block;
      }
    }

    div[id*='QuickAddSelectedItems'] {
      display: block;
    }
  }
}

// Product search parts
.productsContainer {
  .productsAddPanel {
    display: none;
  }

  .RetailPriceDiv {
    min-height: 20px;
  }
}

.itemcell {
  &:hover {

    .productdetailsqty,
    .descriptionHyperLink {
      text-decoration: none;
      font-weight: bold !important;
    }
  }

  .productsStockLabel {
    display: none;
  }
}

.optionsdropdown {
  @extend .form-control;
  display: inline-block;
  text-transform: uppercase;
  max-width: 200px;
  border-radius: 0;
  margin-right: 10px;

  &:first-of-type {
    margin-right: 10px;
  }
}

.leadTimePanel {
  font-weight: bold !important;
}

.productOptionsPanel {
  span {
    display: block;
  }

  * {
    margin-top: 10px;
  }
}

.enquire-button {
  display: none;
}

.promo-stuff, .shipping-stuff, .workwear-stuff {
  display: none;
}

@for $i from 0 through 9 {
  .sector_40#{$i} {
    #formsHolder {
      display: block;
    }

    .workwear-form {
      display: block !important;
    }

    .workwear-stuff {
      display: block;
    }
  }

  .sector_30#{$i} {
    .stockLinkPanel,
    .product-add {
      display: none;
    }

    .continue-shopping {
      margin-top: 15px;
    }

    .enquire-button {
      display: block;
    }


    #productBuySection {
      display: none;
    }

    #formsHolder {
      display: block;
      margin-top: 0 !important;
    }

    .promo-form {
      display: block !important;
    }

    .promo-stuff, .shipping-stuff {
      display: block;
    }
  }
}

@for $i from 0 through 9 {
  .sector_4#{$i} {
    #formsHolder {
      display: block;
    }

    .workwear-form {
      display: block !important;
    }

    .workwear-stuff {
      display: block;
    }
  }

  .sector_3,
  .sector_10,
  .sector_3#{$i} {
    #productBuySection {
      display: none;
    }

    #formsHolder {
      margin-top: 0 !important;
    }

    .promo-form {
      display: block !important;
    }

    #formsHolder {
      display: block;
    }

    .promo-stuff, .shipping-stuff {
      display: block;
    }
  }
}

.videoLinkButton {
  display: inline-block;
  min-width: 100px;
}

.pdfLinkButton {
  display: block;
}

#formsHolder {
  display: none;
}

.promo-form {
  display: none;
}

.workwear-form {
  display: none;

  .react-datepicker-wrapper {
    width: 100%;
  }
}

.sector_81 {
  .workwear-form {
    display: block;
  }
}

.request-quote-btn {
  @extend .cssbutton;
  border-radius: 0;

  &:hover, &:focus, &:active {
    background-color: $heatonsOrange !important;
    border-color: $heatonsOrange !important;
    outline: 0;
    box-shadow: none !important;
  }
}

.Newproductdetailsiconpanel {
  img {
    max-height: 25px;
    max-width: 25px;
    margin-right: 5px;
  }
}

/**
Search product stuff
 */
#FilterContainer {
  .productsFilterPanel {
    @extend .row;
  }

  .manufacturerTopFilterPanel, .subgroupTopFilterPanel {
    display: none;
  }

  .topFilterPanel {
    @extend .col-12, .col-lg, .col-xl-2;

    input, select {
      @extend .form-control;
      border-radius: 0;
    }
  }

  .stockTopPanel {
    @extend .col-12, .col-lg, .col-xl-2;

    input {
      display: none;
    }

    input:checked + label {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    label {
      display: block;
      color: $heatonsOrange;
      font-size: 14px;
      padding: 15px 0;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

  }
}

.productdetailsdatalist {
  img[src='/Images/no.png'] {
    display: none;
  }
}

#WorkwearForm, #PromoForm {
  background-color: #e7e7e9;
  padding: 10px;

  .addExtraPosition {
    display: inline-block;
    margin-left: 15px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .positionSelects:not(:first-of-type) {
    margin-top: 5px;
  }

  .custom-file {
    label {
      cursor: pointer;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
    cursor: pointer;
  }

  .request-quote-btn {
    border-radius: 0;
    background-color: #e7792b;
    border: 0;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }
  }

  .mt_2_5 {
    margin-top: 2.5rem !important
  }
}

.contractTopPanel {
  display: none;
}

/**
Media Queries for product page and search page
 */

@media (max-width: 1200px) {
  .Products .itemcell {
    width: 100%;
  }

}

/**
For Tablets
 */
@media (max-width: 768px) {
  .optionsdropdown {
    max-width: 100% !important;
  }

  .ProductDetails #formsHolder {
    display: none !important;
  }

  .lowerDetails hr {
    display: none !important;
  }
}

/**
For Below Tablets
 */
@media (max-width: 767px) {
  .additionalProducts .ProductDetailsAdditionals .AdditionalItemPanel .AdditionalDescLabel {
    min-height: 0 !important;
  }
}

@media (min-width: 767px) and (max-width: 1200px) {
  .Products .itemcell {
    width: 50%;
  }
}


#myModal {
  background-color: rgba(0, 0, 0, 0.6);

  .cursor {
    cursor: pointer;
  }

  video {
    width: 80%;
    margin: 25px auto auto;
    height: auto;
    border: 0;
    border-radius: 0;
    background-color: transparent;
  }

  .close {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    margin-right: 5px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.entryTypeCheckBox {
  display: block;

  input[type=checkbox] {
    margin-right: 2px;
  }
}

.errorLabel {
  @extend .alert, .alert-warning;
  display: inline-block;
  font-weight: bold;
  margin-top: 5px;
}
