.testimonial{
    .bgText{
         font-size: 40px;
     color:#FFF;
     font-weight: 200;
     line-height: 52px;
     position: relative;
     left:10px;
    top: 40px; 
    }

    .borderTextWrap{
        padding-right:100px;
    }
}

.testimonialSpan{
	margin-top:30px;
	display: block;
	position:relative;
	top: 40px; 
}