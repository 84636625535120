#HEADTABLE, #MENUTABLE {
  height: 60px;
  //border-bottom: 1px solid #ccc;
}

#HEADTABLE {
  height: auto;
  padding: 0;
  background: white;
}


#MENUTABLE {
  height: 42px;
  line-height: 42px;
}

.headerLogoWrapper img {
  height: $headerSize/2 + 20px;
  margin: ($headerSize/4 - 10px) 0px
}

.keywordsearchcontrol {

  .keyLabel {
    display: none;
  }


  margin-top: 64px;
  width: 60%;
  height: 30px;
  -moz-background-size: auto 20px;
  -o-background-size: auto 20px;
  -webkit-background-size: auto 20px;
  background-size: auto 20px;
  background: #E4E4E3 url(../images/search_icon.svg) no-repeat 10px 5px;
  float: left;
  margin-right: 30px;


  input {
    background: transparent;
    border: 0 none;
    height: 30px;
  }

  input[type="submit"] {
    text-transform: uppercase;
    float: right;
  }
}

.headerLoginStatusWrapper, .headerCartWrapper {
  width: 50px;
  height: 50px;
  overflow: hidden;
  display: block;
  background: #ebebeb;
  float: left;
  margin-left: 0;
  margin-top: ($headerSize/4);
}

.headerLoginStatusWrapper {
  background-size: auto 30px;
  overflow: visible;
  background: transparent url('../images/profile_icon.svg') no-repeat center center;
}

.headerLoginStatusWrapper.loggedIn {
  display: none;
  background-size: auto 30px;
  overflow: visible;
  background: transparent url('../images/profile_icon_logged-in.svg') no-repeat center center;
}

.headerCartWrapper {
  background-size: auto 30px;
  overflow: visible;
  text-indent: -99999px;
  background: transparent url('../images/cart_icon.svg') no-repeat center center;
  position: relative;
}

.headerCartWrapper.hasItems {
  background: transparent url('../images/cart_icon_colour.svg') no-repeat center center;
}

.cartPanel {
  position: absolute;
  z-index: 99;
  background: #fff;
  padding: 20px;
  right: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  .divider {
    display: none;
  }
}

.subTotalPanel {
  text-indent: 0;
}

#MainMenu ul {

  margin: 0;
  padding: 0;
  list-style: none;

  li {
    float: left;
    line-height: 40px;
    padding-right: 30px;

    a {
      color: #4D4D4D;
      text-transform: uppercase;
    }
  }
}

#MENUTABLE .p {
  display: none;
}


.registerPopup {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 100%;
  right: 0;
  background: #ebebeb;
  z-index: 951;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: none;

  h3 {
    font-size: 1rem;
  }

  a {
    color: #222;
    text-decoration: none;
  }
}

.registerPopup.in {
  display: block;
}

.login-input {
  height: 40px;
  width: 100%;
  line-height: 40px;
  background: #fff;
  display: block;
  // border:0 none;
  margin-bottom: 10px;
  text-indent: 10px;
}

.is-invalid {
  border: 1px solid red;
}

.toggleRegisterPopup {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
}

#HEADTABLE {
  .menuShadow {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 99;
    padding: 0;
    background: #E4E4E3;
    height: 40px;
  }

  .header-contents {
    min-height: $headerSize;
  }
}

.headerRightWrapper {
  min-height: $headerSize;
}

.headerKeywords {
  margin-top: $headerSize/4;
  min-height: $headerSize/4;

  .keywordSearch {
    margin-top: 5px;
    height: ($headerSize/2 - 10px);
  }

  .input-group-append {
    margin-top: 5px;
    height: ($headerSize/2 - 10px);
  }
}

meta#user_details[content*='{'] + #HEADTABLE .headerLoginStatusWrapper {
  display: none !important;
}

meta#user_details[content*='{'] + #HEADTABLE .headerLoginStatusWrapper.loggedIn {
  display: block !important;
}

.headCart:not(.orderPadCart) {
  text-indent: 0;

  &:hover {
    .mini-cart {
      border: 1px solid #e7e7e9;
    }

    #minicartPanel {
      display: block !important;
    }
  }

  .mini-cart {
    .csscontrolBoxHeader {
      display: none;
    }
  }

  #minicartPanel {
    position: relative;
    top: 53px;
    left: -351px;
    background: #fff;
    border: 1px solid #e7e7e9;
    width: 400px;
    z-index: 965;
    text-indent: 0;
    padding: 10px;

    *:not(a):not(input) {
      cursor: default;
      color: initial;
    }

    a, input {

      cursor: pointer;
    }

    img {
      max-width: 50px;
      margin-right: 5px;
    }

    .lblMCProdCode {
      display: none;
    }

    .lblMCProdQty {
      margin-left: 10px;
    }

    .prodPrices {
      display: inline-block;
      margin-left: 5px;
    }

    .csscartImageButton {
      display: block;
      margin: auto;
    }

    .hlMCProdDesc {
      display: inline-grid;
      width: 200px;
      overflow: hidden;
      height: 1.5em;
    }

    div[id*='subTotalPanel'] {
      text-align: right;
      margin-top: 10px;
      margin-bottom: 10px;

      span {
        text-decoration: none !important;
        font-weight: bold !important;
        text-transform: uppercase;
      }
    }
  }

  .badge {
    float: right;
    margin-top: 2px;
    margin-right: 2px;
    color: orange;
    background-color: rgba(0, 0, 0, 0.1);

    &:empty {
      display: none;
    }
  }
}


body #MainMenu {
  background-color: #e7e7e7;
}

#MainMenu .menu-ul {
  height: auto !important;
}

@media(min-width: 1200px) and(max-width: 1500px) {
  #MainMenu ul li {
    float: left;
    line-height: 40px;
    padding-right: 18px;
    font-size: 11px;
  }

  body #MENUTABLE {
    height: auto;
  }

  #MainMenu .menu-ul {
    overflow: auto;
  }


}


@media(max-width: 1200px) {

  meta#user_details[content*='{'] + #HEADTABLE .headerLoginStatusWrapper.loggedIn {
    display: inline-block !important;
  }

  .headerLogoWrapper img {
    height: 40px;
    margin-top: 30px;
  }


  .homepage-feature-slide {
    height: 300px !important;
  }


  .mobileHeader {
    display: block;
  }
  body .mobileHeader .toggleSearch {
    width: 44px;
    height: 50px;
    float: none;
    display: inline-block;
    line-height: 50px;
    text-align: center;
    margin-top: -20px;
    position: relative;
    top: -20px
  }
  .mobileHeader .toggleSearch img {
    width: 27px
  }
  .mobileHeader .menuButton {
    width: 50px;
    height: 50px;
    float: none;
    display: inline-block;
    line-height: 50px;
    text-align: center;
    margin-top: -20px;
    position: relative;
    top: -20px
  }
  .mobileHeader .menuButton img {
    width: 27px
  }
  .mobileHeader .vatToggleWrapper {
    display: none
  }
  .mobileHeader .headerCartWrapper, .mobileHeader .headerLoginStatusWrapper {
    display: inline-block;
    float: none
  }
  .mobileHeader .minicartcontrol {
    position: absolute
  }
  .mobileHeader .cartLowerContainer {
    max-width: 100%;
    flex: none
  }
  body .mobileHeader .rangeTopRight {
    position: absolute;
    top: 0;
    right: 10px;
    text-align: right;
    width: 60%
  }
  .mobileHeader .headerLoginStatusWrapper.loggedIn {
    display: none
  }
  body .pt-7 {
    padding-top: 50px;
  }

}

@media (max-width: 321px) {
  .mobileHeader {
    .headerLogoWrapper {
      display: none !important;
    }

    .rangeTopRight {
      width: 75% !important;
    }
  }
}