.hoverCardAlt{
 .image{
 	padding-bottom: 50%;
 	background-size:cover;
 	background-position:center center;
 	background-repeat:no-repeat;
 }

 .caption{
 	background:#D8D8D8;
 	padding:20px;
 	font-size: 18px;

 	a{
 		display: block;
 		margin-top:10px;
 		color:#222;
 		font-size: 14px;
 		text-transform:uppercase;

 		&:hover{
 			text-decoration: none;
 		}
 		img{
 			width:25px;
 			margin-right:5px;
 		}
 	}
 }

}