@import 'widgets/hoverCard';
@import 'widgets/fullWidthImage';
@import 'widgets/testimonial';
@import 'widgets/squareCard';
@import 'widgets/twoColWithImage';
@import 'widgets/carousel';
@import 'widgets/scrollerTable';
@import 'widgets/gallerySection';
@import 'widgets/hoverCardAlt';
@import 'widgets/timeline';

.homepage-feature-slide {
  height: calc(100vh - 140px);
}

#features {
  i, svg {
    color: $heatonsOrange;
  }
}

.testimonial-btn {
  margin-left: 15px !important;
  border-radius: 0;
  background-color: $heatonsOrange;
  border-color: $heatonsOrange;
  text-transform: uppercase;
  color: white !important;
  cursor: pointer;

  &:hover {
    background-color: $heatonsOrange;
    border-color: $heatonsOrange;
  }
}

.swipe:not(.mw-swipe) {
  overflow: hidden;
  max-width: 372px;
  padding: 0;
  margin: auto;
}

.swiper-slide {
  video {
    height: 100%;
  }
}

.btn-link {
  i {
    color: $heatonsOrange;
  }
}

.brand-showcase {
  img {
    max-width: 250px;
    width: 100%;
  }
}

.intro-text {
  .intro-text-p {
    margin-top: 2.3rem;
    font-size: 1.3rem;
    line-height: 1.7;
  }
}

.bg-wrap {
  position: relative;
  overflow: hidden
}

.bg-fw {
  width: 100vw !important;
  background-repeat: no-repeat;
  background-size: contain;
}

.accordion-border {
  padding-bottom: 10px;
  border-bottom: 1px solid $lightGrey;
}

.accordion-border:not(:first-of-type) {

  margin-top: 10px;
}

.btn-accordion {
  @extend h3;
  color: $fontColor;
  text-decoration: none;

  &:hover, &:active, &:focus {
    color: $heatonsOrange;
    text-decoration: none;
  }
}

@media(max-width: 991.98px) {
  .bg-wrap_body {
    height: 33vw;
    min-height: 200px
  }
}

@media(min-width: 992px) {
  .bg-wrap_body {
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    min-width: 1px;
    max-width: none;
    height: 100%;
    min-height: 100%;
    max-height: none;
    margin: 0;
    z-index: 0
  }

  .bg-wrap_body + * {
    margin-top: 0
  }

  .bg-wrap_body.bg-wrap_body-md-right {
    right: 0
  }

  .bg-wrap_body.bg-wrap_body-md-left {
    left: 0
  }
}

@media(min-width: 992px) {
  .bg-wrap_body-sizing-1 {
    width: 50vw
  }
}

.widgets1 {
  section {
    margin-bottom: 50px;
  }
}

.img-swap {
  img {
    -webkit-filter: grayscale(1);
    transition: all .5s ease-in-out;

    &:hover {
      -webkit-filter: grayscale(0);
    }
  }
}

$transition: all .4s ease-in-out;
#fourbytwo {

  h3 {
    margin: auto;
    max-width: 99%;
    text-align: center;
  }

  p.text-center {
    width: 80%;
    margin: auto !important;
  }

  .row div {
    background-position: center;
    background-size: cover;
    background-clip: content-box;
    transition: $transition;
    height: 300px;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      background-color: rgba(0, 0, 0, 0.5);
      background-position: center;
      background-size: cover;
      background-clip: content-box;
      padding: 0 .5rem !important;
      transition: $transition;
    }

    p {
      transition: $transition;
      visibility: hidden;
      opacity: 0;
      margin: 0;
      height: 0;
    }

    h3 {
      transition: $transition;
    }

    &:hover {
      &:after {
        transition: $transition;
        background-color: rgba(0, 0, 0, 0.8);
      }

      h3 {
        transition: $transition;
        position: relative;
        margin-bottom: 30px;

        &:after {
          transition: $transition;
          content: "";
          position: absolute;
          bottom: -5px;
          left: 40%;
          width: 20%;
          border-bottom: 2px solid #e7792b;
        }
      }

      p {
        transition: $transition;
        visibility: visible;
        opacity: 1;
        margin: unset;
        height: unset;
      }
    }

    a {
      z-index: 2;
      text-decoration: none;
      transition: $transition;

      &:focus, &:hover {
        color: white;
        text-decoration: none;
        transition: $transition;
      }
    }
  }
}

.homepage-feature-slide .overlay-container {
  background-color: transparent;
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  background-color: rgba(0, 0, 0, 0.3);

  .video-text-overlay {
    display: none;
    vertical-align: middle;
    text-align: center;


    h1 {
      color: white;
      margin: auto;
      width: 45%;
    }

    .overlay-p {
      margin-top: 10px;
      max-width: 50%;
      margin-left: auto;
      margin-right: auto;
      color: white;
    }
  }
}

@media (max-width: 1200px) {
  .overlay-container .video-text-overlay h1 {
    width: 80%;
    font-size: 18px;
  }
}
