.bg-lightgray {
  background-color: #e7e7e9;
}

.text-orange {
  color: $heatonsOrange;
}

.top-2 {
  top: 2rem;
}

.mh-1000 {
  min-height: 1000px !important;
}

.list-style-none {
  li {
    list-style: none;
    margin-left: -10px;

    i {
      margin-right: 5px;
    }
  }
}

.bg-image {
  background-repeat: no-repeat;
  background-size: cover;
}

@for $i from 6 through 15 {
  .pb-#{$i} {
    padding-bottom: #{$i}rem;
  }
}

@for $i from 6 through 15 {
  .pt-#{$i} {
    padding-top: #{$i}rem;
  }
}

@for $i from 6 through 15 {
  .mb-#{$i} {
    margin-bottom: #{$i}rem;
  }
}

@for $i from 6 through 15 {
  .mt-#{$i} {
    margin-top: #{$i}rem;
  }
}

.font-size-4_5 {
  font-size: 4.5rem;
}

.nordic {
  font-family: NordiquePro-Regular, Montserrat, serif;
}

.font-size-1 {
  font-size: 1rem !important;
}

.mt-20 {
  margin-top: 20%;
}

.outline-none {
  outline: none !important;
}

.border-bottom-orange:after {
  content: ""; /* This is necessary for the pseudo element to work. */
  display: block; /* This will put the pseudo element on its own line. */
  margin: 0 auto; /* This will center the border. */
  width: 65%; /* Change this to whatever width you want. */
  padding-top: 15px; /* This creates some space between the element and the border. */
  border-bottom: 2px solid $heatonsOrange;
}

.font-color {
  color: #4D4D4D;
  &:hover{
    color: $heatonsNavy;
  }
}

.color-red {
  color: red !important;
}

.mh-75 {
  min-height: 75px;
}

.bg-heatons-orange {
  background-color: $heatonsOrange !important;
}

.bg-lightgrey {
  background-color: $lightGrey !important;
}

.bg-grey {
  background-color: $grey !important;
}

.bg-darkgrey {
  background-color: $darkgrey !important;
}

.color-heatons-orange {
  color: $heatonsOrange;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.vw-95 {
  width: 95vw;
}

.font-size-3 {
  font-size: 3rem;
}

.mb-1-5 {
  margin-bottom: .375rem !important;
}

.react-bootstrap-table {
  table {
    min-width: 950px;
  }
}

#AccountsPage {
  .react-bootstrap-table {
    table {
      min-width: 0;
    }
  }
}

//@include media-breakpoint-up(sm) { ... }
//@include media-breakpoint-up(md) { ... }
//@include media-breakpoint-up(lg) { ... }
//@include media-breakpoint-up(xl) { ... }
