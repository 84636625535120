.dateRangeTable {
    td {
        min-width: 150px;

        input {
            @extend .form-control;
            border-radius: 0;
            width: 100% !important;
        }
    }
}