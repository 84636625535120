.custom-product-container {

    .details-container,
    .contact-details {
        transition: all 800ms ease-in-out;
    }


    .custom-product-header {
        font-size: 26px;
        margin-bottom: 5px;
        text-transform: uppercase;
    }

    label {
        text-transform: uppercase;
        font-weight: bold;
    }

    .submit-btn {
        background-color: $orange;
        color: white;
        border-radius: 0;
        text-transform: uppercase;
        font-weight: bold;
    }

    .form-control {
        border-radius: 0;
    }

    select, input, textarea {

        &:focus {
        }
    }

    small {
        margin-left: 1rem;
    }

    .custom-product-form {
        background-color: $lightGrey;
    }

    .relatedImage {
        max-width: 100%;
    }

    .product-title {
        display: block;
        font-size: 1.1rem;
        color: $fontColor;
        margin-top: .5rem;
    }

    .relatedCustomProduct a {
        color: $fontColor;

        &:hover {
            text-decoration: none !important;

            .product-title {
                font-weight: bold;
            }
        }

        &.customProductButton {
            display: block;
            width: 100%;
            text-align: center;
            color: white;
        }
    }
}

.mt-10 {
    margin-top: 7.5rem;
}