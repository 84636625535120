.consumables {
  input[type='image'] {
    height: 75px;

    & + span {
      display: block;
    }
  }

  .consumablesActive {
    span {
      font-weight: bold;
    }
  }

  .consumablesselection {
    tr {
      td[style] span {
        @extend h3;
        display: block;
        border-bottom: 1px solid $heatonsOrange;
        margin-bottom: 15px;
      }
    }
  }

  .searches {
    max-height: 500px;
    overflow-y: scroll;
  }

  .ModelColOne, .ModelColTwo, .ModelColThr {
    a {
      @extend .btn, .btn-link;
    }
  }

  //some stuff in important (hiding things etc..)


  .itemcell {
    min-height: 500px;
    height: 100% !important;
    width: 20% !important;

    .thumbnail {
      max-width: 200px;
      display: block;
      margin: auto;
    }

    .productsPricePanel {
      width: 100% !important;
      text-align: left !important;
    }

    .productsRetailLabel {
      display: block;
    }

    #VatPrices {
      display: none;
    }

    .productsDescriptionLabel {
      display: block;
      min-height: 40px;


    }

    .productsAddPanel {
      margin-top: 65px;
      width: 100% !important;

      input {
        display: block !important;
        width: 100% !important;
      }
    }

    .colourSwatch {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    table {
      width: 100%;
    }
  }

  .productdetailsdatalist {
    &:before {
      @extend h3;
      content: 'Product';
      display: inline-block;
      border-bottom: 1px solid #e7792b;
      margin-bottom: 15px;
    }
  }
}


.consumablesselection {
  width: 100%;
}