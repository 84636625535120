.mainCarousel{
	height:500px;
	.carouselImage{
		min-height:500px;
		background:#fafafa;
		width:100%;
		background-size:cover;
		background-position:center center;
	}
}

.navigationCarousel{
	width:75%;
	margin:0 auto;
	margin-top:20px;
	height:150px;
	.carouselImage{
		min-height:150px;
		background:#ebebeb;
		width:100%;
		margin:0px 10px;
		background-size:cover;
		background-position:center center;
		opacity: 0.7;

		&.slick-current{
			opacity: 1;
		}
	}
}

.slick-list, .slick-track{
	height:100%;
}

.mainCarouselWrap{
	position: relative;
}

.leftArrow{
	position:absolute;
	left:30px;
	top:50%;
	transform: translateY(-50);
	z-index: 9;
}

.rightArrow{
	position:absolute;
	right:30px;
	top:50%;
	transform: translateY(-50);
	z-index: 9;
}