.gallerySectionHeader{
	background:#777776;
	color:#fff;
	padding:15px 0px; 
	margin-bottom:40px;

	&.dark{
		background:#444;
	}
}

.vMainGallery, .vMainGalleryThree{
	width:80%;
	height:350px;
	float: left;

	.carouselImage{
		min-height:350px;
		background:#fafafa;
		width:100%;
		background-size:cover;
		background-position:center center;
	}


	.slick-list, .slick-track{
		height:100%;
	}
}

.vMainGalleryThree{
	width:66%;
	height:320px;

}

.vMainGalleryNavigation, .vMainGalleryNavigationThree{

	width:20%;
	float:left;
	margin-top:-5px;

	.carouselImage{
		min-height:80px;
		background:#ebebeb;
		width:100%;
		margin:5px 10px;
		background-size:cover;
		background-position:center center;
		opacity: 0.7;

		&.slick-current{
			opacity: 1;
		}
	}
}

.vMainGalleryNavigationThree{
	width:33%;	
	height:340px;
	.carouselImage{
		min-height:100px;
	}
}