@mixin checkout-header-label {
  display: block;
  font-size: 1.2em;
  font-weight: 700;
}

// Hides fast order panel
.fastOrderPanel {
  display: none;
}

// Hides Voucher Panel
div[id*='VoucherPanel'] {
  @extend .col-12;

  .inputBox {
    @extend .form-control;
    display: inline;
    max-width: 250px;
  }

  input[type=submit] {
    height: 100%;
    display: inline-block;
  }
}

// Status at the top of order panel
.cartProgress {
  @extend .row;
  background: #E4E4E3;
  margin-top: 50px;
  margin-bottom: 50px;

  .cartProgressItem {
    @extend .col-4;
    padding: 10px;
    text-align: center;

    &.f {
      color: #fff;
      background: $orange;
    }

    &.l {
      background: #CBCCCB;
    }
  }
}

.errorLabel:empty {
  display: none;
}

// Select Department Line and Add Reference Per Line checkbox styling
.cartCheckBoxesWrapper {
  @extend .col-12;
  display: block;
  width: 100%;
  margin-bottom: 25px;

  .cartCheckBoxes {
    @extend .mt-4;

    .cartCheckBoxWrapper {
      display: inline-block;
      max-width: 300px;

      &:first-of-type:after {
        content: '|';
        display: inline-block;
        color: $orange;
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    input {
      display: none;

      &:checked + label {
        color: $orange;
      }
    }

    label {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}


.emailPanel {
  .checkoutInfoLine {
    padding: 0 !important;
  }
}

// making the HTML fit the bootstrap column way of doing things
#multiView {
  @extend .row;

  .noStockErrorWrapper {
    display: block;
    margin: 15px 0 15px;
  }
}

.cartLowerContainer {
  @extend .col-12, .col-xl-9;
}

// Total Panel Styling
.cssCartTotalPanel {
  @extend .col-3;

  background: #E4E4E3;
  padding: 25px;
  height: 100%;

  &:before {
    content: $totalCartTitle;
    text-transform: uppercase;
    display: block;
    font-size: 1.5em;
    padding-bottom: 5px;
    border-bottom: 1px solid #58585a;
    margin-bottom: 10px;
  }

  .cartupdatebuttons {
    margin-bottom: 20px;

    .cssupdateImageButton + .csscontinueImageButton {
      width: 49% !important;
    }
  }

  .transferNewLines {
    display: inline-block;
    width: 49%;

    input {
      display: none !important;

      &:checked + label {
        color: $orange;
      }
    }

    label {
      width: 100%;
      text-align: center;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    & + .csstransferImageButton {
      margin-left: 4px;
    }
  }

  .pricesPanel {
    padding: 5px;
  }

  // Formats subtotal, total etc. text
  .cartTotalsLineLeft {
    width: 75%;
    float: left;
    margin-bottom: 15px;

    .cartTotalLabel {
      font-weight: bold;
    }

    + .cartTotalValue.alignTextRight {
      float: right;
      width: 25%;
      text-align: right;
    }
  }

  .cartTotalsLineRight {
    width: 25%;
    float: left;
    margin-bottom: 15px;

    span {
      float: right;
      text-align: right;
    }
  }

  //display these once moved
  .freeItemsCart, .cartButtonsPanel {
    display: block;
  }

  // add inc VAT to total line
  .cartTotal {
    .cartTotalLabel.alignTextRight {
      &:after {
        display: inline-block;
        content: 'inc VAT';
        padding-left: 3px;
      }
    }
  }

  // Format cart buttons
  .cartButtonsPanel input,
  .cartupdatebuttons input {
    width: 49%;
    display: inline-block;
    background: #9F9F9E;
    color: #fff;
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;

    &.cssemptyImageButton {
      float: right;
    }

    &:last-of-type {
      width: 100%;
    }

    &.cssCheckoutButton {
      margin-top: 5px;
      background-color: $orange;
    }
  }

  .cartButtonsPanel .cssbutton.printReportButton {
    width: 49%;
    display: inline-block;
    background: #9F9F9E;
    color: #fff;
    font-size: 14px;
    margin-bottom: 10px;
    float: right;
    text-align: center;
  }

  // Hide total saved text
  div[id*='cartTotalsSavingsPanel'] {
    display: none;
  }

  // Swap savings panel
  .cssSavingPanel {
    > div {
      margin-bottom: 10px;

      span {
        display: inline-block;
        width: 74.4%;
        color: $swapGreen;
        font-weight: bold;

        + span {
          text-align: right;
          font-weight: normal;
          width: 24.4%;
        }
      }
    }

    .swapSavingsPanelRowThree {
      display: none;
    }

    .swapSavingsPanelRowTwo {
      display: none;
    }
  }
}

// Free items list
.freeItemsCart {
  margin-top: 20px;

  .freeItems {
    @extend .list-group;
    display: inline-block;
    margin: 0 auto;

    li {
      display: inline-block;
      list-style: none;

      .freeItemDesc {
        display: none;
      }
    }
  }

  img {
    display: inline-block;
    margin-right: 10px;
  }

  .freeItemDesc {
    display: inline-block;
  }

  .freeItemPrice {
    display: none;
  }

  .freeItemsLabel {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
}

// Cart Table Styling
.CartGridView, .authgrid {

  //restrict the size of icons
  .divCartAddtofav,
  .divCartProdDescCell {
    img {
      @extend .mr-1;
      max-width: 20px;
      max-height: 20px;
    }
  }

  // Style swap accept checkbox
  .swapCheckBoxWrapper {
    input[type='checkbox'] {
      display: block;
      width: 15px;
      height: 15px;
      margin-top: 5px;
    }
  }

  // Override the stock message with custom message
  .lblNoStockMsg {
    font-size: 0;
    color: white;
  }

  .lblNoStockMsg:after {
    display: block;
    content: $nextDayMessage;
    color: #333;
    font-size: 0.9rem;
    margin-top: -20px;
  }

  // format swap stock description
  span[id*='altDescriptionLabel'] {
    @extend .text-muted;
    font-style: italic;
    color: $swapGreen !important;

    &:empty {
      display: none;
    }

    &:before {
      @extend .mr-1;
      display: inline-block;
      content: $swapDescriptionPrefix;
      font-weight: bold;
      font-style: normal;
      color: $swapGreen;
    }

    + span {
      display: none;
    }
  }

  span[id*='altCodeLabel'] {
    display: none;
  }

  .CartGridView, .authgrid {
    margin-bottom: 250px;

    tbody {
      tr:first-of-type {
        th:nth-child(3) {
          color: white;
        }
      }
    }
  }

  // Hides redundant edit button
  .cartDeliverybutton {
    display: none;
  }

  // Style qty box
  .divCartQtyBox {
    input {
      @extend .form-control;
      width: 45px;
    }
  }

  // Cart product description
  .lblCartProdDesc {
    @extend .mt-2;
    font-size: 17px;
    font-weight: bold;
  }

  // Make swap option text green
  .cssSwap.altSavingLabel {
    color: $swapGreen;
    font-weight: bold;
  }


  // Center align remove item button
  .divCartRemoveButton {
    text-align: center;
  }

  .lbCartCode {
    display: block;
  }

  // Style swap savings panel
  .cssSavingPanel {
    > div {
      margin-bottom: 10px;

      span {
        display: inline-block;
        width: 74.4%;
        color: $swapGreen;
        font-weight: bold;

        + span {
          text-align: right;
          font-weight: normal;
          width: 24.4%;
        }
      }
    }

    .swapSavingsPanelRowThree {
      display: none;
    }

    .swapSavingsPanelRowTwo {
      display: none;
    }
  }

  // Hide total saving div
  div[id*='cartTotalsSavingsPanel'] {
    display: none;
  }

  // Format drop-downs in bootstrap format
  .refTextBox, .DeptDropDownList {
    @extend .form-control, .mt-2, .mb-3, .d-inline-block;
    max-width: 49%;
  }

  .divCartAddtofav,
  .divCartProdDescCell {
    img {
      @extend .mr-1;
      max-width: 20px;
      max-height: 20px;
    }
  }

  .divCartProdDescCell {
    .iconPanel {
      @extend .mb-2;
    }
  }

  .productsImageHyperLink img {
    max-width: 100px;
    max-height: 100px;
  }
}

// Hide swap option pricing
.divCartProdTotalPrice + .cartSwapRow,
.lblCartProdTotalPrice + .cartSwapRow,
.divCartProdPrice + .cartSwapRow,
.lblCartProdPrice + .cartSwapRow {
  display: none;
}

// Style no stock error message as bootstrap alert
.noStockErrorWrapper .lblStkError {
  @extend .alert, .alert-warning;
  border-radius: 0;
}

//Stops flicker of moving DOM Elements
.freeItemsCart, .cartButtonsPanel, .noStockErrorWrapper {
  display: none;
}


// Cart checkout forms
.Checkout {

  .checkoutPanel {
    @extend .row;
  }

  .checkoutCompletePanel {
    @extend .col-4;
  }

  .checkoutReviewPanel {
    @extend .col-4;
  }

  .additionalInfoPanel,
  .orderDetailsPanel {
    @extend .col-8;
  }

  .cartSummaryPanel {
    @extend .col-4;
    padding: 0 !important;

    .cartPanelInfoWrapper {
      background-color: #e4e4e3;
      padding: 15px 15px 30px 15px;

      .checkoutInfoLine {
        @extend .row;

        span {
          @extend .col-6, .mt-3;
          font-weight: bold;
        }

        span:nth-of-type(2) {
          text-align: right;
          font-weight: normal;
        }
      }
    }
  }

  .cartPanelInfoHeader {
    @extend .mb-3;
  }

  .orderDetailsPanel {
    order: -1;

    .orderRefInfoLine {
      @extend .mb-2;

      .orderRefLabel {
        @include checkout-header-label;
      }

      .orderRefTextBoxWrapper {
        display: block;

        table {
          width: 100%;
        }
      }
    }

    .dxeTextBox {
      background-color: transparent;
      border: 0;

      .dxeEditAreaSys {
        border: 1px solid #ced4da !important;
        border-radius: 0;
      }
    }

    .orderRefTextBoxWrapper {
      &:after {
        @extend .text-muted;
        display: block;
        content: 'Not sure what to put? This can be your purchase order number, your name or something memorable.';
        margin-left: 15px;
      }
    }

    input:not([type='checkbox']) {
      @extend .form-control;
      border-radius: 0;
    }

    .adhocWrapper {
      &:before {
        @include checkout-header-label;
        content: 'Specifiy an Ad Hoc Address';
      }

      .adHocCheckBox {
        @extend .form-check;
        margin-left: 5px;
        padding: 0;

        input[type='checkbox'] {
          display: none;

          + label {
            @extend .form-check-label;
            padding: 5px 15px;
            color: white;
            background: $orange;
            margin-top: 5px;
            text-transform: uppercase;

            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }
    }

    .departmentContainer,
    .checkoutContainer {
      @extend .mt-2;

      span {
        @include checkout-header-label;
      }

      select {
        @extend .form-control;
        border-radius: 0;
      }
    }
  }

  .cartInfoHeaderLabel {
    @extend h3;
  }

  .mandatoryLabel {
    display: none;
  }

  .checkoutAddressForm,
  .checkoutInfoLine {
    @extend .col-12;
  }

  .billingAddressLabel,
  .notesPanel span,
  .deliveryAddressLabel {
    @include checkout-header-label;
  }

  .deliveryAddressLabel {
    margin-left: 15px;
  }

  .notesPanel {
    textarea {
      @extend .form-control, .w-100;
      border-radius: 0;
    }
  }

  .labelColumn {
    margin-right: 10px;
    min-width: 100px;

    span {
      @extend label;
    }
  }

  .checkoutInputColumn {
    select,
    input {
      @extend .form-control;
      border-radius: 0;
    }
  }

  .checkoutAddressForm {
    .checkoutInfoLine:first-of-type {
      padding: 0;
      margin-bottom: 5px;
    }

    div[id*='invPanel'],
    div[id*='delPanel'] {
      @extend .row;

      .checkoutInfoLine {
        min-height: 50px;
      }
    }

    div[id*='invPanel'] {
      .checkoutInfoLine:first-of-type {
        @extend .col-6;
        margin-bottom: 0;
      }
    }

    .checkoutInfoLine:first-of-type {
      @extend .col-12;
    }

    .checkoutInfoLine:not(:first-of-type) {
      @extend .col-6;
    }


    .checkoutAddressRow {
      margin-left: 0 !important;
      margin-bottom: 5px;
    }
  }

  .checkoutValidatorColumn {
    span {
      margin-left: 5px;
    }

    span:first-of-type {
      color: red;
    }

    span:nth-of-type(2) {
      @extend .text-muted;
    }
  }

  .additionalInfoPanel {
    margin-top: 20px;

  }

  span[id*='customMessage'],
  div[id*='DNSPanel'] {
    display: none;
  }

  .checkoutReviewPanel {
    display: none !important;
  }

  .checkoutWaitLabelWrapper,
  .checkoutCompleteButton,
  .termsAndConditionsCheckoutLink {
    @extend .col-12;
  }

  .termsAndConditionsCheckoutLink {
    @extend .form-check;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;

    input {
      @extend .form-check-input
    }

    label {
      @extend .form-check-label;
    }
  }

  .sameCheckBox {
    @extend .form-check;
    margin-left: 15px;

    input {
      @extend .form-check-input;
    }

    label {
      @extend .form-check-label;
    }
  }

  .checkoutCompleteButton {
    input {
      display: block;
      width: 110px;
    }
  }

  .checkoutWaitLabelWrapper {
    img {
      display: block;
      width: 150px;
    }
  }

  .errorLabel {
    color: red;
  }
}

span[id*="VoucherErrorLabel"]:not(:empty) {
  @extend .alert, .alert-warning;
  display: inline-block;
  margin: 0 !important;
}
