.cssAccountPage > div {
  display: block;
  padding: 20px;
  width: 31%;
  margin: 1%;
  float: left;
  background: #EDEDED;
  height: 150px;
  overflow: auto;

  span {
    display: block;
    height: 50px;
  }

  h2 {
    text-transform: uppsercase;
    height: 50px;

    a {
      font-size: 1.5rem;
      color: #575756;
    }
  }
}


.cssAccountPage {
  overflow: auto;
  margin-bottom: 50px;
}

.cssAccountPage:after {
  content: "";
  clear: both;
}

.accountTitle {
  padding: 30px 10px;
}

.newdetails, .editInfoPanel {

  margin-bottom: 50px;

  table {
    width: 100%;

    td {
      width: 50%;
    }
  }

  input, select {
    height: 45px;
    line-height: 45px;
    border-radius: 3px;
    display: block;
    border: 1px solid #ccc;
    width: 100%;
    margin-bottom: 15px;
    text-indent: 15px;
  }
}

#pswd_info h4 {
  font-size: 12px;
}

.editInfoPasswordValidator {
  position: relative;
  height: 1px;
  width: 100%;

  #pswd_info {
    position: absolute;
    transform: translateX(10%);
    background: #E4E4E3;
    padding: 20px;
    width: 300px;
    padding-bottom: 0px;
    left: 100%;
    margin-top: -40px;
  }
}

.h1Pad {
  padding-top: 50px;
  padding-bottom: 30px;
}

.newaccountsubmit {
  margin-bottom: 50px;
}


.infoLabel {
  font-size: 18px;
  margin-bottom: 10px;
  width: 75%;
  display: block;
  float: left;
  padding-left: 20px;
}

.reqContainer {
  float: right;
}

.editInfoSectionWrapper {
  margin-top: 50px;
}

.authMsgLabel {
  @extend .alert, .alert-danger;
  margin-top: 10px;
  display: inline-block;
  font-weight: bold;
}