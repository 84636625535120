$svgMaxWidth: 100%;

body.accounts {
  background-color: #efefef !important;
}

#AccountsPage {

  .heatons-orange {
    color: $heatonsOrange !important;
  }

  .accounts {
    .salutation {
      color: $heatonsOrange;
    }

    .main-welcome {
      padding: 35px 10px 10px;
    }

    .main-dash {

    }


    .recent {
      @extend .mb-5, .mt-2;

      &:empty {
        display: none;
      }
    }
  }

  .react-bootstrap-table {

    label[for] {
      width: 100%;
    }

    input {
      margin-left: 0 !important;
      width: 100% !important;
    }

    .table thead th {
      vertical-align: top;
    }

    .product-image-sm {
      max-width: 150px;
      max-height: 100px;
    }

    .product-text {
      word-break: break-all;
      max-width: 100%;
    }

    caption {
      text-align: right;
    }

    .btn.btn-link {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .react-datepicker__day:not(.react-datepicker__day--outside-month) {
    font-weight: bold;
  }


  .react-datepicker__input-container {
    input {
      @extend .form-control
    }
  }

  .recharts-wrapper {
    width: 100% !important;

    svg.recharts-surface {
      width: 100% !important;
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .list-group-compact {
    li {
      padding: 0.25rem 0.5rem;
    }
  }

  .float-left, .float-right {
    .react-datepicker__input-container {
      max-width: 125px;

      input {
        text-align: center;
      }

    }
  }


  .col {
    .icon-holder {
      position: relative;
      width: 100%;
      min-height: 40px;

      .icon {
        position: absolute;
        top: -36px;
        left: calc(50% - 36px);
        max-width: 72px;
        max-height: 72px;
      }
    }

    h4 {
      min-height: 85px;
    }
  }

  .pp-stats {
    .col {
      min-height: 120px;
    }
  }

  .recents {
    height: 100%;
    padding: 5px 15px;

    &:empty {
      display: none;
    }
  }

  .total-spend {
    .row {
      background-color: #ffffff;
    }
  }

  .quick-links {
    li {
      padding-left: 0 !important;
    }
  }

  .table-hover {
    tbody {
      tr:hover {
        background-color: $heatonsOrange;
        color: white;

        td {
          border-color: $heatonsOrange;
        }
      }
    }
  }

  .recent {
    td:first-of-type {
      width: 150px;
    }

    td:nth-of-type(2) {
      width: 250px;
    }
  }
}

.bt-none {
  border-top: none !important;
}

.p-none {
  padding: 0 !important;
}

.table-mb-0 {
  margin-bottom: 0 !important;

  .table {
    margin-bottom: 0 !important;
  }
}

.react-bootstrap-table {
  table {
    background-color: white;
  }

  img {
    margin: auto;
    display: block;
  }
}

.loading-table {
  display: block;
  position: relative;
  width: 72px;
  height: 72px;
  margin: auto;

  div {
    position: absolute;
    top: 27px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #ef7d00;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}


/***
Tims changes SCSS for acocunts
 */
.pp-stats {
  .icon-holder {
    position: relative;
    width: 100%;
    min-height: 40px;

    .icon {
      position: absolute;
      top: -36px;
      left: calc(50% - 36px);
      max-width: 72px;
      max-height: 72px;
    }
  }

}

@media (max-width: 1500px) {
  .total-spend .pp-stats .iconWrap:nth-of-type(7) {
    display: none !important;
  }
}

.react-bootstrap-table {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.react-bootstrap-table table {
  table-layout: auto;
}

#AccountsPage {
  tr:hover a {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  a:not(.e-link):not(.grid-link) {
    color: rgba(0, 0, 0, .5);
    font-size: .9rem;
    line-height: unset;
    text-transform: uppercase;
    font-weight: 900;

    &:hover {
      color: #e7792b;
    }
  }

  a.active {
    color: $heatonsOrange !important;
  }
}

@media (max-width: 1024px) {

  body.accounts {
    overflow-x: hidden;
  }

  body #AccountsPage .accounts .main-welcome {
    padding-right: 15px;
    padding-left: 15px;
  }


  padding-right: 15px;
  padding-left: 15px;
  h2 {
    font-size: 1.5rem;
  }

  .iconWrap {
    min-height: 200px;
  }

  .advertWrap {
    margin-bottom: 30px;
  }

  .padMe {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .table-bordered th, .table-bordered td {
    min-width: 200px;
  }

  .table-bordered th, .table-bordered td {
    min-width: 200px;
    min-height: 30px;
    padding: 15px 10px;
  }

}


#quickLinks {
  .list-group-item {
    border: 0;
  }
}


.rep_details {
  span, h5 {
    margin-left: 10px;
  }

  span {
    color: white;
    font-weight: bold;
  }
}

#RepDetails {
  .rep-info-container {
    margin: 0 !important;
  }
}

.rep-info-container {
  @extend .bg-darkgrey;
  color: white !important;
  padding: .75rem 1.25rem;
}


.authCartWrapper{
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 20px;
  .authLabel {
    @extend .alert, .alert-info;
    width: 100%;
    display: block;
  }

  .delAddressPanel, .invAddressPanel {
    @extend .col-12, .col-lg-6;
  }

  .authCartCheckBoxWrapper {
    @extend .col-12;
    display: block;
    width: 100%;
    margin-bottom: 25px;

    input {
      display: none;

      &:checked + label {
        color: $orange;
      }
    }

    label {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .lineDepartmentCheckBox:first-of-type:after {
      content: '|';
      display: inline-block;
      color: $orange;
      margin-left: 10px;
      margin-right: 10px;
    }

  }

  .authgrid {
    @extend .col-12, .col-lg-9;

    //restrict the size of icons
    img {
      display: none;
      //@extend .mr-1;
      max-width: 20px;
      max-height: 20px;
    }

    table {
      width: 100%;
    }

    // Style swap accept checkbox
    .swapCheckBoxWrapper {
      input[type='checkbox'] {
        display: block;
        width: 15px;
        height: 15px;
        margin-top: 5px;
      }
    }

    // Override the stock message with custom message
    .lblNoStockMsg {
      font-size: 0;
      color: white;
    }

    .lblNoStockMsg:after {
      display: block;
      content: $nextDayMessage;
      color: #333;
      font-size: 0.9rem;
      margin-top: -20px;
    }

    // format swap stock description
    span[id*='altDescriptionLabel'] {
      @extend .text-muted;
      font-style: italic;
      color: $swapGreen !important;

      &:empty {
        display: none;
      }

      &:before {
        @extend .mr-1;
        display: inline-block;
        content: $swapDescriptionPrefix;
        font-weight: bold;
        font-style: normal;
        color: $swapGreen;
      }

      + span {
        display: none;
      }
    }

    span[id*='altCodeLabel'] {
      display: none;
    }

    .CartGridView, .authgrid {
      margin-bottom: 250px;

      tbody {
        tr:first-of-type {
          th:nth-child(3) {
            color: white;
          }
        }
      }
    }

    // Hides redundant edit button
    .cartDeliverybutton {
      display: none;
    }

    // Style qty box
    .divCartQtyBox {
      input {
        @extend .form-control;
        width: 45px;
      }
    }

    // Cart product description
    .lblCartProdDesc {
      @extend .mt-2;
      font-size: 17px;
      font-weight: bold;
    }

    input[id*='qtyTextBox']{
        @extend .form-control;
        width: 45px !important;
    }

    // Make swap option text green
    .cssSwap.altSavingLabel {
      color: $swapGreen;
      font-weight: bold;
    }


    // Center align remove item button
    .divCartRemoveButton {
      text-align: center;
    }

    // Hide cart item code
    .lbCartCode {
      display: none;
    }

    // Style swap savings panel
    .cssSavingPanel {
      > div {
        margin-bottom: 10px;

        span {
          display: inline-block;
          width: 74.4%;
          color: $swapGreen;
          font-weight: bold;

          + span {
            text-align: right;
            font-weight: normal;
            width: 24.4%;
          }
        }
      }

      .swapSavingsPanelRowThree {
        display: none;
      }

      .swapSavingsPanelRowTwo {
        display: none;
      }
    }

    // Hide total saving div
    div[id*='cartTotalsSavingsPanel'] {
      display: none;
    }

    // Format drop-downs in bootstrap format
    .refTextBox, .DeptDropDownList {
      @extend .form-control, .mt-2, .mb-3, .d-inline-block;
      max-width: 49%;
    }

    .divCartAddtofav,
    .divCartProdDescCell {
      img {
        @extend .mr-1;
        max-width: 20px;
        max-height: 20px;
      }
    }

    .divCartProdDescCell {
      .iconPanel {
        @extend .mb-2;
      }
    }

    .productsImageHyperLink img {
      max-width: 100px;
      max-height: 100px;
    }

    .txtRef, .ddlDept {
      @extend .form-control, .mt-2, .mb-3, .d-inline-block;
      max-width: 49%;
    }

    .descLinkButton {
      @extend .mt-2;
      font-size: 17px;
      font-weight: bold;
      display: block;
    }

    .lblPack {
      display: block;
    }
  }


  .authCartButtonWrapper {
    @extend .col-12, .col-lg-3;

    background: #E4E4E3;
    padding: 25px;
    height: 100%;

    &:before {
      content: 'Actions';
      text-transform: uppercase;
      display: block;
      font-size: 1.5em;
      padding-bottom: 5px;
      border-bottom: 1px solid #58585a;
      margin-bottom: 10px;
    }

    table {
      width: 100%;
      td{
        width: 50%;
      }

      input[type=submit], a {
        width: 100%;
        display: block;
        text-align: center;
      }
    }

    .cssAuthButton {
      width: 100%;
      display: block;
      margin-top: 1px;
    }
  }

  .authCartSubTotalWrapper {
    text-align: right;
    margin-right: 10px;

    span[id]{
      text-decoration: none !important;
    }
  }

}


#main .authCartWrapper {
  @extend .row;
}
