@font-face {
    font-family: 'NordiquePro-Bold';
    src: url('../fonts/3A3229_0_0.eot');
    src: url('../fonts/3A3229_0_0.eot?#iefix') format('embedded-opentype'), url('../fonts/3A3229_0_0.woff2') format('woff2'), url('../fonts/3A3229_0_0.woff') format('woff'), url('../fonts/3A3229_0_0.ttf') format('truetype');
}

@font-face {
    font-family: 'NordiquePro-Regular';
    src: url('../fonts/3A3229_1_0.eot');
    src: url('../fonts/3A3229_1_0.eot?#iefix') format('embedded-opentype'), url('../fonts/3A3229_1_0.woff2') format('woff2'), url('../fonts/3A3229_1_0.woff') format('woff'), url('../fonts/3A3229_1_0.ttf') format('truetype');
}

body {
    //doesnt look good
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    font-size: .8em !important;
}

h3 {
    font-size: 32px;
    margin-bottom: 30px;
}


.largeText {
    p {
        font-size: 22px;
        font-weight: 200;
    }
}


p.lead {
    margin-top: 20px;
    margin-bottom: 20px;
}

a.btn {
    color: #222;
}

.massiveText {
    font-size: 55px;
    font-weight: 200;
    line-height: 70px;
}