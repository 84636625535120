@import "~bootstrap";
// LAYOUT
@import "variables";
@import 'header';
@import 'typography';
@import "widgets";
@import 'navigation';
@import 'admin';
// Page Imports
@import "pages/financial";
@import "pages/cart";
@import "pages/custom-products";
@import "pages/accounts";
@import "pages/products";
@import "pages/orderpads";
@import "pages/top-list";
@import "pages/calloffs";
@import "pages/favourite-products";
@import "pages/reports";
@import "pages/ordermessage";
@import "pages/editinfo";
@import "pages/consumables";
@import "pages/fastorder";
@import "pages/self-serve";
@import "pages/dashboard";
//Components
@import "cart-saved-sidebar";
@import "utilities";

@media(max-width: 576px) {
  .cc_dialog.simple {
    max-width: 100%;
  }
}

@media(min-width: 576px) {
  .container {
    max-width: 540px
  }
}

@media(min-width: 768px) {
  .container {
    max-width: 720px
  }
}

@media(min-width: 992px) {
  .container {
    max-width: 960px
  }
}

@media(min-width: 1200px) {
  .container {
    max-width: 1200px
  }
}

@media(min-width: 1600px) {
  .container {
    max-width: 1400px
  }
}

.cc_b_ok, .cc_cp_f_save button {
  background-color: $heatonsOrange !important;
}

.notie11 iframe {
  height: calc(100vh - 140px) !important;
}

.f-caps {
  text-transform: uppercase;
}

section {
  padding: 40px 0;
}

.greyBg {
  background: #E4E4E3 !important;
}

.lightGreyBg {
  background: #F2F1F0 !important;
}

.container-fluid {
  max-width: 95%;
}

body {
  color: #4D4D4D;
  background-color: #fff !important;
}

a:hover {
  color: $heatonsNavy;
}

.breadcrumb {
  background-color: #fff !important;
}

// Old style may revert depending on usuage of new way
// .bgText{
//     background-image: linear-gradient(180deg, #76B82A 100%, #76B82A 0%);
//     background-size: 0 100%;
//     background-repeat: no-repeat;
//     text-decoration: none;
//     background-size: 100% 100%;
//     display: inline;
//      box-shadow: 10px 0 0 #76B82A, -10px 0 0 #76B82A, -10px 10px 0 #76B82A, 10px 10px 0 #76B82A;
//     font-weight: 200;
//     color:#fff;
// }


.bgText {
  text-decoration: none;
  display: inline;
  font-weight: 200;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-box-decoration-break: clone;
  padding: 0 15px 5px;
  box-decoration-break: clone;
  line-height: 1.4;
  font-family: Montserrat, serif;

  &.orangeText {
    background-color: rgba(231, 121, 43, 0.7);

    &.solid {
      background-color: rgba(231, 121, 43, 1);
    }
  }
}

.smallText {
  font-size: 14px;
}

p.lead {
  font-size: 16px;
  font-weight: 600;
}

.greenText {
  color: #76B82A;
}

h2 {
  font-weight: 300;

  strong {
    font-weight: 600;
  }
}

.cta, .cssbutton {
  background: #e7792b;
  padding: 5px 10px;
  color: #fff;
  margin: 20px 0;
  display: inline-block;
  border: 0 none;

  &:hover {
    color: #fff;
    text-decoration: none;
  }

  &:hover {
    filter: brightness(70%) !important;
    -webkit-filter: brightness(70%) !important;
    transition: all .15s ease;
  }
}

@media (max-width: 767px) {
  .cta {
    width: 100%;
  }
}

.noTop {
  padding-top: 0;
}

.mobileHeader {
  display: none;
}

.closeMenu {
  display: none;
}

@media (max-width: 1200px) {

  #MENUTABLE {
    overflow: auto;
  }

  .closeMenu {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    background: #e7792b url(../images/close_menu.svg);
    width: 50px;
    padding: 5px;
    z-index: 99;
    height: 50px;
    background-size: 30px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .hoverCard .image {
    height: 90%;
  }
  .hoverCard {
    margin-bottom: 30px;
  }

  .fullWidthImage {
    padding-bottom: 63% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .squareCard {
    margin-bottom: 30px;
  }

  .testimonial .borderTextWrap {
    padding-right: 0;
  }

  .twoColText {
    margin-right: 0 !important;
    margin-top: 30px;
  }

  .container-fluid {
    max-width: 100%;
  }

  .mainCarousel .carouselImage, .mainCarousel {
    height: 350px;
  }

  .mainCarousel .carouselImage, .navigationCarousel {
    height: 80px;
  }
  .navigationCarousel {
    width: 100%;
  }

  .hoverCardAlt {
    margin-bottom: 30px;
  }

  .twoColImage {
    margin-bottom: 30px;
  }

  .largeText .pr-5 {
    padding-right: 0 !important;
  }

  .desktopHeader {
    display: none;
  }
  .mobileHeader {
    display: block;

    .toggleSearch {
      width: 50px;
      height: 50px;
      float: none;
      display: inline-block;
      line-height: 50px;
      text-align: center;
      margin-top: -20px;
      position: relative;
      top: -20px;

      img {
        width: 27px;
      }
    }

    .menuButton {
      width: 50px;
      height: 50px;
      float: none;
      display: inline-block;
      line-height: 50px;
      text-align: center;
      margin-top: -20px;
      position: relative;
      top: -20px;

      img {
        width: 27px;
      }

    }

    .vatToggleWrapper {
      display: none;
    }


    .headerCartWrapper, .headerLoginStatusWrapper {
      display: inline-block;
      float: none;
    }

    .minicartcontrol {
      position: absolute;
    }

    .cartLowerContainer {
      max-width: 100%;
      flex: none;
    }


    .rangeTopRight {
      position: absolute;
      top: 00px;
      right: 30px;

      text-align: right;
      width: 50%;
    }

    .headerLoginStatusWrapper.loggedIn {
      display: none;
    }
  }

  .cssCartTotalPanel {
    max-width: 100%;
    flex: none;
  }

  #MainMenu {
    overflow: auto;
    background: #FFF;

  }

  #MainMenu ul.menu-ul {
    overflow: auto;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
    width: 100%;


    li {
      float: left;
      line-height: 60px;
      padding-right: 30px;
      width: 100%;

      border-bottom: 1px solid #ebebeb;

      a {
        padding: 0px 30px;
        color: #4d4d4d;
        text-transform: uppercase;
        font-size: 22px;
      }
    }
  }

  #MENUTABLE {
    line-height: 42px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 99;
    transform: translateX(-100%);
    transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);

    &.in {
      transform: translateX(0%);

    }
  }

  .theSearchRow {
    display: none;
  }

  #MainMenu .submenu > .submenu {
    display: none !important;
    min-height: 350px;
  }

  #MainMenu .submenu {
    position: static;
  }

  #MainMenu ul.menu-ul li {
    width: 100%;
    padding: 0px;
  }

  #MainMenu .submenu-btn {
    color: #4d4d4d;
    text-transform: uppercase;
    border: none;
    background: none;
    height: 60px;
    width: 100%;
    text-align: left;
    line-height: 60px;
    border-bottom: 1px solid #ebebeb;
    padding: 0px 30px;
    margin: 0px;
    display: block;
  }

}


.breadcrumb {
  background: transparent;
}

.breadcrumbLink, .breadcrumbSeparator, .homeLinkText {
  padding: 10px 5px;
  color: #000;
  font-size: .8rem;
  text-transform: uppercase;
  display: block;

  &:first-of-type {
    padding-left: 0;
  }
}

.itemcell {
  width: 20%;
  float: left;
  padding: 10px;
  height: 380px;
  border-bottom: 1px solid $lightGrey;

  .thumbnail {
    width: 100%;
    display: block;
    height: 220px;
    overflow: hidden;
    line-height: 220px;
    text-align: center;

    img {
      max-width: 100%;
      height: auto;
      display: inline-block;
      max-height: 100%;
    }
  }
}


.descriptionHyperLink {
  color: #222;
  font-weight: 200 !important;
  height: 58px;
  display: block;
  overflow: hidden;
  font-size: 13px;
  text-transform: uppercase;
}

.productsLongDescriptionLabel {
  display: none;
}

.productsPriceLabel {
  color: $orange;
}

.productsStockLabel {
  display: block;
  opacity: 0.6;
}

//Hide stock message until JS processed
.productsStockLabel:not(.processed) {
  //display: none !important;
}

.productsAddLabel {
  display: none;
}

.productsQtyTextBox {
  height: 40px;
  border: 1px solid #ebebeb;
  width: 20%;
  margin-right: 5%;
  float: left;
  text-align: center;
}

.productsTextHyperLink {
  // display: none;
}

.productsPackLabel {
  display: none;
}

.productsBuyImageButton {
  height: 40px;
  color: #222;
  text-align: center;
  border: 0 none;
  width: 75%;
  background-size: 25px;
  background: #ebebeb url(../images/cart_icon.svg) no-repeat 10px 10px;
  float: left;
}

.productsAddPanel {
  margin-top: 10px;
  overflow: auto;
}

.productdetailsitem {
  position: relative;

  .productsIconPanel {
    position: absolute;
    top: 0px;
    right: 0px;

    img {
      width: 25px;
    }
  }
}

.ProductsCompareBox {
  display: none;
}

.pnlProdPagerTop, .compareButton {
  display: none;
}

.productdetailsdatalist {
  overflow: auto;
}

.produceSideBar {
  background: #ebebeb;
  min-height: 900px;
  padding: 15px;
  margin-top: 30px;
}

#Browse {
  ul {
    @extend .p-0, .p-lg-3;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .browseCellImageHyperlink a {
    display: block;
    text-align: center;

    img {
      display: inline-block;
    }
  }

  li {
    @extend .col-12, .col-sm-5, .col-lg-2;
    min-height: 200px;
    display: inline-block;
    margin-bottom: 10px;
    border: 2px solid $lightGrey;
    padding: 10px;
    margin-right: 10px;

    &:hover {
      -webkit-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.55);
      -moz-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.55);
      box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.55);
    }

    img {
      min-height: 200px;
      max-width: 100%;
    }
  }

  .browseCellTextHyperlink {
    @extend h5;
  }

  .browseCellCountLabel {
    display: block;
    text-align: right;
  }
}

.browseCellTextHyperlink {
  display: block;
  text-align: center;
  color: #000;
}

.brandImageWrapper {
  display: none;
}

.cssbutton {
  margin: 0;
  text-transform: uppercase;
}

.LoginPanel {
  tr:not(:first-of-type) {
    display: none;
  }

  span {
    display: block;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
  }

  input {
    @extend .form-control;
    border-radius: 0;
  }

  a {
    padding: .375rem .75rem;
    color: #fff;
    background-color: $heatonsOrange;
    height: 38px;
    line-height: 1.5;
    display: inline-block;
    text-align: center;
    font-size: 1rem;
  }
}

table.cssgrid {

  border: 0 none;

  td, th {
    border: 0 none;
    padding: 15px;
  }

  th {
    border-bottom: 1px solid #ebebeb;
  }
}

.footer {
  padding: 50px 0;
  background: #ededed;
  margin-top: 40px;

  h5 {
    font-size: 14px;
    color: #575756;
    margin-bottom: 0;
    font-weight: bold;
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      min-height: 20px;
      line-height: 20px;
    }

    a {
      font-size: 14px;
      color: #575756;
    }
  }
}

.mt {
  margin-top: 25px;
}

.bottomFooter {
  background: #9D9D9C;
  height: 60px;
  line-height: 60px;
  color: #fff;
}

#adminFoot {
  background-color: #9d9d9c;

  a {
    display: none;
  }

  span {
    color: white !important;
    font-size: 11px;
  }
}

.box {
  background: #EDEDED;
  padding: 30px;
  height: 100%;

  &.register {
    background-color: #cccccc;
  }
}

.padRow {
  margin-top: 50px;
}

.loginLabel {
  font-size: 20px;
  text-transform: uppercase;
  height: 60px;
  display: block;
  line-height: 60px;
}

.login-input {
  height: 60px;
  border-radius: 0;
  border: 0 none;
}

#loginSubmit {
  background: #575756;
  height: 60px;
  font-size: 20px;
  line-height: 60px;
  padding: 0 30px;
  text-transform: uppercase;
  margin: 0;
}

#createAccount {
  background: $heatonsOrange;
  height: 60px;
  font-size: 20px;
  line-height: 60px;
  padding: 0 30px;
  text-transform: uppercase;
  margin: 0;
}

.registerCheck {
  font-size: 20px;
  height: 70px;
  line-height: 70px;
  margin-bottom: 1rem;

  input {
    width: 50px;
    height: 50px;
    background: #fff;
    display: inline-block;
  }
}

.check {
  width: 50px;
  height: 50px;
  background: #fff;
  margin-right: 10px;
  display: inline-block;
  float: left;
  position: relative;
  top: 5px;
}

.attributeFilterHeadingFilterOption, .PopupHeader {
  border-top: 1px solid #575756;
  color: #575756;
  text-transform: uppercase;
  display: block;
  width: 100%;
  padding-top: 5px;
  margin-top: 5px;
  font-weight: 600;
  margin-bottom: 10px;
}

.MoreCheckBoxItem {
  margin-right: 5px;
}

.FilterOption br {
  display: none;
}


.FilterOption, .FilterPopupPanel {
  margin-top: 20px;

  a {
    color: #575756;
    text-transform: uppercase;
    display: block;
  }
}

.bottomFooter {
  position: relative;

  #horizonVersion {
    position: absolute;
    bottom: 1px;
    left: -45px;
    max-height: 9px;
    font-size: 8px;
    text-align: center;
    line-height: 8px;
  }

  #adminBtn {
    display: none;
  }
}

#CENTER {
  min-height: 36vh;
}


// Hiding filters we dont need
.HiddenFilter {
  display: none;
}

.attributeFilterHeading {
  border-top: 1px solid #575756;
  color: #575756;
  text-transform: uppercase;
  display: block;
  width: 100%;
  padding-top: 5px;
  margin-top: 5px;
  font-weight: 600;
  margin-bottom: 10px;
}

.attributeFilterHeadingFilterOption {
  border-top: 1px solid #575756;
  color: #575756;
  text-transform: uppercase;
  display: block;
  width: 100%;
  padding-top: 5px;
  margin-top: 5px;
  font-weight: 600;
  margin-bottom: 10px;
}

// Pagination
.productsPager {
  padding: 0;
  text-align: center;
  margin-top: 15px;

  a, span {
    color: #222;
    text-decoration: none;
    display: inline-block;
    background: rgba(0, 0, 0, .1);
    padding: 10px 5px;
    margin: 0 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 45px;

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }

  a.currentPagePagerLink, span.currentPagePagerLink {
    background: rgba(0, 0, 0, 0.2);
  }
}

.pageSizeWrapper {
  text-align: right;
  margin-top: 20px;

  select {
    height: 40px;
    margin-left: 20px;
  }
}

.registerCheck {
  a {
    text-decoration: none;
    color: $fontColor;
  }
}

i, svg {
  color: $grey;
}

.add-to-favourites {
  select, input:not([type=submit]) {
    @extend .form-control;
    margin-bottom: 15px;
  }
}

input.form-control, select.form-control, label.form-control {
  border-radius: 0 !important;
}


/**
Quotes CSS (from old site)
 */

.pControlTable .pControlIconPanel, .pControlTable .PDPackWrapper, .pControlTable .pControlAddLabel, .pControlTable .pControlCode span {
  display: none
}

.pControlTable .pControlItemTable {
  width: 18%;
  margin: 10px 1%;
  float: left;
  position: relative;
  height: 325px !important;
  -moz-transition: all, .5s, ease-in-out;
  -o-transition: all, .5s, ease-in-out;
  -webkit-transition: all, .5s, ease-in-out;
  transition: all, .5s, ease-in-out;
  font-size: 0.9em
}

.pControlTable .pControlItemTable .pControlAddPanel {
  position: absolute;
  width: 100%;
  left: -10px;
  padding-left: 10px;
  bottom: 127px;
  max-height: 0px;
  overflow: hidden;
  z-index: 99;
  -moz-transition: all, .5s, ease-in-out;
  -o-transition: all, .5s, ease-in-out;
  -webkit-transition: all, .5s, ease-in-out;
  transition: all, .5s, ease-in-out
}

.pControlTable .pControlItemTable .productsDescriptionLabel {
  color: #333;
  font-size: 0.9em;
  font-weight: 300 !important;
  -moz-transition: all, .5s, ease-in-out;
  -o-transition: all, .5s, ease-in-out;
  -webkit-transition: all, .5s, ease-in-out;
  transition: all, .5s, ease-in-out
}


.pControlTable .pControlItemTable:after {
  content: " ";
  width: 104%;
  height: 1px;
  position: absolute;
  bottom: -11px;
  left: -12px;
  padding-right: 14px
}

.pControlTable .pControlDescPanel {
  padding: 5px 10px 0 10px
}

.pControlTable .pControlDescPanel a {
  color: #333
}

.pControlTable .pControlQtyTable {
  position: absolute;
  bottom: 15px;
  left: 10px;
  font-weight: bold;
  display: block;
  width: 100%
}

.pControlTable .pControlFooter {
  position: absolute;
  bottom: 30px
}

.pControlTable .pControlBestPrice {
  font-size: 16px
}

.pControlTable .pControlCode {
  font-size: 12px
}

.pControlTable .pControlCode a {
  color: #333
}

.pControlTable .pControlQtyTextBox {
  width: 27%;
  height: 34px;
  position: relative;
  margin-top: 7px;
  display: inline-block;
  margin-bottom: 1px
}

.pControlTable .pControlBuyButton {
  width: 66%;
  margin-top: 7px;
  display: inline-block;
  margin-bottom: 1px;
  text-transform: lowercase;
  font-size: 19px;
  position: relative;
  top: 1px
}

.pControlTable .productsImageHyperLink {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 153px;
  margin-bottom: 10px;
  padding: 5px
}

.pControlTable .productsImageHyperLink img {
  width: 153px !important;
  display: block;
  margin: 0 auto
}

@media all and (max-width: 1024px) {
  .pControlTable .pControlItemTable {
    width: 22.5%;
    margin: 10px 1.25%
  }
}

@media all and (max-width: 768px) {
  .pControlTable .pControlItemTable {
    width: 30%;
    margin: 10px 1.66%
  }
}

@media all and (max-width: 600px) {
  .pControlTable .pControlItemTable {
    width: 45%;
    margin: 10px 2.5%
  }
}

@media all and (max-width: 380px) {
  .pControlTable .pControlItemTable {
    width: 84%;
    margin: 10px 8%;
    margin-left: 8%
  }
}

.pControlTable .pControlimage {
  padding: 10px;
  min-height: 180px;
  background: #fff
}

.pControlDetailTable {
  position: relative;
  box-sizing: border-box;
  height: 100%
}


#fullTitleFourImagesWithDescription {
  h4 {
    min-height: 60px;
  }

  p.mh-75 {
    min-height: 115px !important;
  }
}

.Quotes {
  table.cssgrid {
    a {
      color: $heatonsOrange;
    }
  }
}

.ED {
  margin-top: 25px;
  margin-bottom: 100px;

  .referenceLabel {
    @extend h3;
  }

  .subTotalWrapper {
    float: right;
    clear: right;
  }

  input[type=submit] {
    float: right;
    clear: right;
  }
}

.FavImgButton {
  background-image: url("/pages/images/svg/fav.svg");
  width: 24px;
  height: 24px;
  border: none;
  background-position: center;
  background-repeat: no-repeat;
  background-color: $heatonsOrange;
  padding: 15px;
  display: block;
  margin-left: auto;
  color: transparent;
}

.FavImgButton:hover {
  background-image: url("/pages/images/svg/fav-hover.svg");
}

.optionsInfoPanel {
  display: none !important;
}

.alsoBoughtItemsContainer {
  table {
    width: 100%;
  }
}

.savedorderscontrol + h2 {
  display: none;
}

.container-sagepay {
  text-align: center;

  iframe {
    border: 0;
    max-width: 600px !important;
    margin: auto;
    height: 800px;
  }
}

.account-application.processed {
  display: block;

  span {
    @extend label;
  }

  input:not([type=submit]) {
    @extend .form-control, .mb-2;
  }

  input[type=submit] {

  }
}

div.account-application {
  display: none;
}

.cssMassAuthButton {
  float: right;
  margin-top: 15px;
}

#selectAllAuth {
  float: right;
  margin-top: 15px;
  max-height: 30px;
  font-size: .8rem;
}

.account-application {
  .lblSuccess {
    @extend .alert, .alert-success;
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.hoverImage {
  img {
    display: block;

    & + img {
      display: none;
    }
  }

  &:hover {
    img {
      display: none;

      & + img {
        display: block;
      }
    }
  }
}

.ProductDetails .callOffPanel[id*="callOffPanel"] {
  display: none;
}

#sector-browse {
  table.twoColDataList,
  table.sectortable {
    width: 100%;
  }

  .sectorcell {
    width: 66%;

    table {
      width: 100%;
    }
  }

  .sectorimagecell {
    width: 33%;

    img {
      width: 100% !important;
    }
  }

  .dataListCell {
    height: 0;
    width: 50%; // make sure the table is 50/50
  }

  .sectorspanel {
    height: 100%;

    > div {
      padding: 10px;
    }

    > a {
      display: block;
      text-align: center;
      margin-bottom: 5px;
      background-color: $heatonsOrange;
      color: $light;
      text-transform: uppercase;
      padding: 5px;
    }
  }

  .cellMoreLink {
    display: block;
    text-align: center;
  }
}
