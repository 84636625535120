.pnlYourSpend,
.pnlOrdersPerMonth {
  text-align: center;

  span {
    @extend .h3;
    display: block;
  }

  select {
    display: block;
    margin: auto;
  }
}

.pnlTopFive {

  .lblTopFiveTitle {
    @extend .h3;
    display: block;
    text-align: center;
  }

  select {
    display: block;
    margin: auto;
  }

  .topFiveGrid {
    width: 100%;

    th {
      text-align: right;
    }

    th:not(:first-of-type) {
      width: 125px !important;
    }

    th:first-of-type {
      text-align: left;
    }

    td {
      width: unset !important;
    }
  }
}