.financial {
    margin-top: 20px;

    div.traderLabelsContainer {
        display: none;
    }

    span.ageLabel {
        display: block;
        font-size: 1.4em;
        border-bottom: 2px solid #b9b9b9;
        padding-bottom: 5px;
        padding-left: 10px;
        margin-bottom: 5px;
    }

    .ageDebtGridView {
        width: 100%;
        margin-bottom: 20px;

        th[scope="col"] {
            text-align: center;
        }

    }

    .paidRadioButtonList:before {
        content: "Select Filter: ";
        display: block;
        margin-left: -20px;
    }

    .paidRadioButtonList {
        margin-left: 20px;

        input[type="radio"] {
            display: none;
        }

        label {
            cursor: pointer;
            font-size: 1em;
            padding: 5px;

        }

        label:hover {
            text-decoration: underline;
        }

        input[type="radio"]:checked + label {
            color: #e9501a;
        }
    }

    .payMessageLabel {
        display: block;
        @extend .alert, .alert-warning;
    }

    .ddlGrpTraders {
        @extend .form-control;
        max-width: 350px;
        margin-bottom: 10px;
    }
}